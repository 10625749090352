html {
  scroll-behavior: smooth;
}

.App {
  text-align: left;
}
@media (min-width: 500px) {
 .App {
    margin: auto;
    max-width: 60rem;
    font-size: 1.0rem;
  }
}

.center {
  text-align: center;
  font-style: italic;
}
.photo {
  margin: auto;
  border: solid rgb(206, 208, 216) 8px;
  max-width: 95% !important;
  margin-top: 3rem;
  margin-bottom: -5px;
}
img {
  display: block;
  margin: auto;
  margin-top: 2rem;
  max-width: 98% !important;
}
/*
@media (max-width: 44rem) {
  img {
    max-width: 86vw;
    margin-left: -10px;
  }
}
*/
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #4d4051;/*
  background-color: #434f68;*/
  min-height: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(14px + 2vmin);
  color: white;
}

body {
  background-color: #3e303d;/*
  background-color: #282c34;*/
  color: white;
}
.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

textarea {
  width: 50rem;
  height: 50vh;
  max-width: 95vw;
  font: 1rem 'Fira Sans', sans-serif;
}

input, textarea {
  background-color: #282c34;
  color: white;
}
.message {
  position: relative;
  margin: 10px;
  border-radius: 40px;
  background-color: #4d4051;
  padding: 24px;
  overflow-wrap: break-word;
  white-space: pre-line;
  text-align: left;
  box-shadow: 20px 20px 53px 11px rgba(0, 0, 0, 0.137);
  /*animation: enlarge 1s;*/
  overflow: hidden;

  transition: max-height 0.8s ease-out;
  max-height: 10000px;
}
@keyframes enlarge {
  0%  {max-height: 500px;}
  99%  {max-height: 2000px;}
  100% {max-height: max-content;}

}
.private.collapsed::after {
  background: rgba(0, 0, 0, 0) linear-gradient(rgba(255, 255, 255, 0), rgb(0, 0, 0)) repeat scroll 0% 0%;
}

.collapsed::after {
  box-sizing: border-box;
  content: "";
position: absolute;
height: 130px;
width: 100%;
top: 140px;
left: 0px;
background: rgba(0, 0, 0, 0) linear-gradient(rgba(255, 255, 255, 0), #4d4051) repeat scroll 0% 0%;
}
.post {
  
  /*background-image: linear-gradient(#00000000, #FFFFFF20);*/
  margin-bottom: 40px;
}

.collapsed:hover {
  box-shadow: 1px 1px 144px 4px rgba(255, 255, 255, 0.048) inset;
  cursor: s-resize;
}
.collapsed {
  z-index: 10;
  max-height: 220px;
  
  /*-webkit-background-clip: text;*/
  /*box-shadow: 0px -20px 73px 11px rgb(255, 255, 255) inset;*/

  

  overflow: hidden;
}

.date {
  margin:10px;
  font-size: 0.9rem;
  color: #948b97;

  margin-left: 24px;
}

.time-entry {
  text-align: right;
}

h2 {
  margin-bottom: 0px;
  margin-left: 34px;
  color: #f1cf36;
}
a.posttitle {
  color: #f1cf36;
  text-decoration: none;
}

.title {
  color: inherit;
  text-decoration: inherit;
}

a {
  color: #fd7878;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

li {
  margin-left: 20px;
}

.toolbar {
  display: flex;
  margin-left: 10px;
  justify-content: space-between;
  align-content: space-between;
}

.topbar {
  display: flex;
  margin-left: 10px;
  align-content: flex-start;
}

.toolbarElement {
  background-color: #4d4051;
  padding: 15px;
  margin: 0px;
  color : #ffffff;
  text-decoration: none;
}

.datebox {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: space-between;
}

.filedrop {
  margin: 3px;
  padding: 10px;
  outline: 2px dashed white;
  cursor: pointer;
}

.private {
  background-color: #282c34;
}

.category {
 color: #bfb99b; 
}

.pagination {
  text-align: center;
  margin: 20px;
}

.none {
  color: white;
}

.none:hover {
  text-decoration: none;
}

.calendarContainer {
  width: 100%;
  border: 0px solid;
}

.react-calendar {
  background-color: rgb(146, 126, 156);
  margin: auto;
}
.react-calendar__navigation, .react-calendar__viewContainer {
  background-color: rgb(189, 186, 181);;
}

.react-calendar__tile--active {
  background: #8e9aa6 !important;
}


.diarypost {
  background-color: #494855;
}
.activeday {
  background: #96b560 !important;
}


.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
}

.link-button:hover,
.link-button:focus {
text-decoration: none;
}


.dayPlan {
  display: grid;
}

.dayEntry {
  margin: 0px;
  padding: 5px;
}

.morning {
  background: #776e53;
}

.day {
  background: #537753;
}

.night {
  background: #535377;
}

.red {
  color: red;
}

.inactive {
  color: #404040;
}